<template>
  <div class="mt-6 mb-6">
    <div class="customer-grid">
      <div class="customer-item" v-for="i in customers">
        <img :src="i.icon" />
        <p>{{i.title}}</p>
      </div>
    </div>
  </div>
</template>



<script>

let customers = [
  {
    icon: require("../../assets/icons/wholesale.svg"),
    title: 'Wholesale'
  },
  {
    icon: require("../../assets/icons/retail.svg"),
    title: 'Retail'
  },
  {
    icon: require("../../assets/icons/partners.svg"),
    title: 'Partners'
  },
  {
    icon: require("../../assets/icons/individual.svg"),
    title: 'Individual'
  }
]
export default {
  data() {
    return {
      customers: customers
    }
  },
  computed: {},
};
</script>


<style scoped>
.customer-grid {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 65%;
  gap: 20px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .customer-grid {
    width: 90%;
  }
}
.customer-item {
  box-sizing: border-box;
  border-radius: 5px;
  width: 130px;
  height: 130px;
  display: grid;
  place-items: center;
}

.customer-item img {
  width: 35px;
  height: 35px;
}
p {
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
</style>
