<template>
  <main class="hero bg-video-wrap">
    <video id="videoElement" muted autoplay @ended="ended"></video>
    <div class="video-overlay">
      <div class="content">
          <h1 class="mt-5">Seafood production at it’s very best.</h1>
          <div class="mb-4">
            <p>
              Our organization intends to use seafoods to solve poverty and
              sustain livelihood; Make available quality seafoods in Africa. We
              produce and supply seafood to Hotels, restaurants, Private
              individuals, fish wholesalers as well as retailers
            </p>
          </div>
          <div class="button-center">
            <button class="button-secondary">
              <a href="/contact">
                <span>Chat with Sales</span>
              </a>
            </button>
          </div>
      </div>
    </div>
  </main>
</template>

<script>
let nArray = [
  {
    // image: require("../../assets/videos/1.png"),
    image: require("../../assets/images/hero.png"),
  }
];

export default {
  data() {
    return {
      nArr: nArray,
      videoStart: 0,
      ended: this.playNext,
    };
  },
  methods: {
    playVideo() {
      console.log(this.nArr);
      var videoPlayer = document.getElementById("videoElement");
      videoPlayer.poster = this.nArr[0].image;
      videoPlayer.src = this.nArr[0].video;
    },
    playNext() {
      try {
        let i = this.videoStart + 1;
        if (i < this.nArr.length) {
          var videoPlayer = document.getElementById("videoElement");
          videoPlayer.poster = this.nArr[i].image;
          videoPlayer.src = this.nArr[i].video;
          this.videoStart = i;
          console.log(i);
        } else {
          this.videoStart = 0;
          i = this.videoStart;
          var videoPlayer = document.getElementById("videoElement");
          videoPlayer.src = this.nArr[i].video;
        }
      } catch (err) {}
    },
  },
  mounted() {
    this.playVideo();
  },
  computed: {},
};
</script>

<style scoped>
/* start of hero */
.hero {
  width: 100%;
  height: 100%;
  background-position: center;
}
.hero .content {
  height: auto;
  margin: 0 auto;
  display: grid;
  place-items: center;
  width: 700px;
}

.hero .button-center {
  display: grid;
  place-items: center;
  gap: 20px;
  width: 400px;
  margin: 0 auto;
}

.hero .content h1 {
  font-size: 60px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
}

.hero .content p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .hero .button-center {
    width: 90%;
  }
  .hero .content {
    width: 90%;
  }
  .hero .content h1 {
    font-size: 30px;
    line-height: 50px;
    width: 100%;
  }

  .hero .content p {
    width: 100%;
  }
}

.bg-video-wrap {
  display: grid;
  position: relative;
}

video {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0.78) 30%
  );
  background-size: 3px 3px;
  z-index: 2;
}

.video-overlay h1 {
  font-weight: bold;
  font-size: 80px;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
}

.video-overlay img {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .video-overlay img {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
