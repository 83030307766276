<template>
  <section>
    <div class="mb-7">
      <div class="center">
        <h1 class="title mt-5 mb-3">Our Mission</h1>
      </div>

      <div class="how-we-work mt-4">
        <div>
          <div class="">
            <h2>
              Everything is well planned, well designed and developed
              wholeheartedly
            </h2>
          </div>
          <div class="mb-4">
            <p>
              Careful planning makes us confident, developed with best practices
              so that the project can be maintained. We always test projects
              before they are shipped.
            </p>
          </div>
          <a class="cta" href="#">Send Quote</a>
        </div>
        <div class="image-wrapper">
        <img src="@/assets/images/mission.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.title {
  color :white
}
.how-we-work {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.image-wrapper {
  padding: 10px ;
  border: 1px solid rgba(255, 255, 255, 0.165);
  margin: 0 auto;
  border-radius: 50%;
}
.how-we-work img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}
.how-we-work h2 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;
  color: white;
}

.how-we-work p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: white;
  margin-bottom: 15px;
}
.how-we-work {
}
</style>
