<template>
  <hero />
  <OurCustomers />
  <WhatDrivesUs />
  <OurProducts />
  <HowWeWork/>
  <Plans/>
  <div style="background: black" class="pt-5">
  <HowWeWork2/>
  <Banner/>
  </div>
</template>

<script>
import hero from "./hero";
import OurCustomers from "./OurCustomers.vue";
import WhatDrivesUs from "./WhatDrivesUs.vue";
import OurProducts from "./OurProducts.vue";
import HowWeWork from "./HowWeWork.vue";
import Plans from "./OurPlan.vue";
import HowWeWork2 from "./HowWeWork2.vue";
import Banner from "./Banner.vue";

export default {
  components: {
    hero,
    OurCustomers,
    WhatDrivesUs,
    OurProducts,
    HowWeWork,
    Plans,
    HowWeWork2,
    Banner
  },
};
</script>
