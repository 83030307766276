<template>
  <section>
    <div class="mt-8">
      <hr />
      <div>
        <div class="mt-5">
          <h1 class="title center">Our Products</h1>
        </div>
      </div>

      <div class="product-grid mt-5">
        <div class="product-item" v-for="i in product">
          <img :src="i.image" />
          <h2>Product Name/Specialty</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.
          </p>
        </div>
      </div>
      <div class="mt-5">
        <button
          class="center button-primary"
          onclick="window.location.href = 'https://site.cosehans.com/products'"
        >
          View More Products
        </button>
      </div>
    </div>
  </section>
</template>

<script>
let products = [
  {
    id: 1,
    image: require("../../assets/images/product/1.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
  {
    id: 1,
    image: require("../../assets/images/product/2.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
  {
    id: 1,
    image: require("../../assets/images/product/3.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
  {
    id: 1,
    image: require("../../assets/images/product/4.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
  {
    id: 1,
    image: require("../../assets/images/product/5.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
  {
    id: 1,
    image: require("../../assets/images/product/6.jpg"),
    title: `Product Name/Specialty`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id quam
            posuere feugiat id dolor rhoncus sociis lectus. Aliquam.`,
  },
];
export default {
  data() {
    return {
      product: products,
    };
  },
};
</script>

<style scoped>
.product-grid {
  display: grid;
  place-items: center;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.product-item {
  background-color: white;
  padding: 10px;
  height: 400px;
  /* border: 0.5px solid rgba(0, 0, 0, 0.151); */
  border-radius: var(--border-radius);
}

.product-item img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin-bottom: 30px;
  border-radius: 5px;
}
.product-item h2 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
}
.product-item p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.8);
}
</style>
