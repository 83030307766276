<template>
  <section>
    <div style="padding-bottom: 5em;">
      <div class="banner">
        <div class="bannerContainer">
          <h3>
            Everything is well planned, well designed and developed
            wholeheartedly
          </h3>
          <div class="mt-1">
            <p>
              Careful planning makes us confident, developed with best practices
              so that the project can be maintained. We always test projects
              before they are shipped.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.banner {
  background: linear-gradient(
      91.08deg,
      rgba(0, 0, 0, 0.8) 27.82%,
      rgba(0, 0, 0, 0) 104.65%
    ),
    url(../../assets/images/banner.png);
  border-radius: 5px;
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 7em;
  padding-bottom: 5em;
}
.banner h3 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}

.banner p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}

.banner .bannerContainer {
  width: 50%;
}

@media (max-width: 768px) {
  .banner .bannerContainer {
    width: 100%;
  }
}
</style>
