<template>
  <div class="mt-8">
    <!-- <div class="mb-3">
      <div class="center">
        <h1 class="title">Our Plan</h1>
      </div>
    </div> -->

    <div class="plans">
      <section>
        <div class="mt-5">
          <h3>
            Everything is well planned, well designed and developed
            wholeheartedly
          </h3>
          <div class="mt-5">
            <p>
              Careful planning makes us confident, developed with best practices
              so that the project can be maintained. We always test projects
              before they are shipped.
            </p>
            <p>
              Careful planning makes us confident, developed with best practices
              so that the project can be maintained. We always test projects
              before they are shipped. Careful planning makes us confident,
              developed with best practices so that the project can be
              maintained. We always test projects before they are shipped.
              Careful planning makes us confident, developed with best practices
              so that the project can be maintained. We always test projects
              before they are shipped.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.plans h3 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 700;
  width: 500px;
  font-size: 30px;
  line-height: 48px;
  color: #ffffff;
}

.plans p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 700px;
  line-height: 32px;
  color: #ffffff;
}

.plans {
  background: linear-gradient(
      91.08deg,
      rgba(0, 0, 0, 0.7) 49.19%,
      rgba(0, 0, 0, 0) 104.65%
    ),
    url(../../assets/images/hero.png);
  height: 100%;
  display: grid;
  place-items: center;
  padding-top: 4em;
  padding-bottom: 4em;
}

@media (max-width: 768px) {
  .plans {
    background: black !important;
  }

  .plans h3 {
    width: 100%;
  }

  .plans p {
    width: 100%;
  }
}
</style>
