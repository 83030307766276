<template>
  <section>
    <hr>
    <div class="mt-8">
      <div class="center">
        <h1 class="title">What drives us</h1>
      </div>

      <div class="drive-grid mt-3">
        <div class="drive-item" v-for="i in drive">
          <img :src="i.image" />
          <div class="overlay">
            <div class="content">
              <h2>{{ i.title }}</h2>
              <p>{{ i.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>

</template>

<script>
let drive = [
  {
    id: 1,
    image: require("../../assets/images/drive/1.png"),
    title: `We are Resilient`,
    description: `The project interface will be designed first, our favorite tool is Figma.`,
  },
  {
    id: 2,
    image: require("../../assets/images/drive/2.png"),
    title: `We are Traditional`,
    description: `Transform design and write business logic here. Choose the technology you want.`,
  },
  {
    id: 3,
    image: require("../../assets/images/drive/3.png"),
    title: `We are Global`,
    description: `After the work is complete, we will send the project and all its assets to you.`,
  },
];
export default {
  data() {
    return {
      drive: drive,
    };
  },
};
</script>

<style scoped>
.drive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  place-items: center;
}
.drive-item {
  border-radius: 5px;
  position: relative;
  width: 300px;
  height: 392px;
  border-radius: var(--border-radius);
}

.drive-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.drive-item .overlay {
  background: linear-gradient(
    180deg,
    rgba(48, 143, 255, 0) 0.71%,
    rgba(0, 0, 0, 0.6) 30%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

.drive-item .overlay .content {
  position: absolute;
  bottom: 10px;
  padding: 20px;
}

.drive-item .overlay .content h2 {
  font-family: "Bangla MN";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 46px;
  color: #ffffff;
}
.drive-item .overlay .content p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
</style>
